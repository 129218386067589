import { ReactElement, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import Carousel from 'react-material-ui-carousel';

import PricingCard from './components/PricingCard';

import PlanIcon from '../../assets/icons/PlanIcon.svg';
import Plan1Icon from '../../assets/icons/Plan1Icon.svg';
import Plan2Icon from '../../assets/icons/Plan2Icon.svg';

const Pricing = (): ReactElement => {
  const [onHoverCard1, setOnHoverCard1] = useState(false);
  const [onHoverCard2, setOnHoverCard2] = useState(false);
  const [onHoverCard3, setOnHoverCard3] = useState(false);

  return (
    <Stack
      id="ADVANTAGES"
      alignItems="center"
      pb={['32px', '80px']}
      px={['16px', 'calc(50% - 600px)']}
      sx={{ width: '100%', backgroundColor: '#FFFFFF' }}
    >
      <Stack pb={2} alignItems="center" justifyContent="center">
        <Typography
          sx={{
            color: '#26476d',
            fontSize: ['18px', '24px'],
            fontFamily: 'Sora',
            fontWeight: 'bold',
            width: '100%',
          }}
          pb={[2, 6]}
          pt={[3, 6]}
        >
          Chegou a hora de escolher seu plano:
        </Typography>
      </Stack>
      <Stack
        gap={4}
        direction={['column', 'column', 'row']}
        sx={{ width: '100%', display: { xs: 'none', md: 'flex' } }}
      >
        <PricingCard
          onHoverCard={onHoverCard1}
          setOnHoverCard={setOnHoverCard1}
          icon={Plan1Icon}
          color="#5fb2d6"
          title=" Requisitos Ambientais"
          description="Este módulo avisa rapidamente sobre contaminação no sistema de distribuição, sua localização com alarmes personalizados por meio de um SMS ou e-mail."
          features={[
            'Gerencie os resultados de análises das estações de tratamento e redes de distribuição. Planeje o trabalho de campo incluindo aplicativos móveis e otimização de rotas.',
            'Imprima automaticamente seu Relatório do Sisagua. Visualize suas zonas prioritárias pelos registros de não conformidade.',
            'Programe planos de recoletas.',
            'Reduza custos com análises laboratoriais.',
          ]}
          height="157px"
          width="100px"
        />
        <PricingCard
          onHoverCard={onHoverCard3}
          setOnHoverCard={setOnHoverCard3}
          icon={Plan2Icon}
          color="#8cd049"
          title="Gestão Operacional"
          description="Este módulo é usado para controle e monitoramento com integração
          de dados para uma maior eficiência nas ações operacionais."
          features={[
            'Planeje o trabalho de campo incluindo aplicativos móveis.',
            'Obtenha Relatórios Gerenciais para estratégia operacional.',
            'Melhore o desempenho dos processos.',
            'Monitore o consumo e estoque de produtos químicos das unidades.',
            'Reduza custos com análises laboratoriais.',
            'Diminua custos operacionais das plantas.',
            'Ganhe assertividade no direcionamento de investimentos.',
          ]}
          height="157px"
          width="156px"
        />
        <PricingCard
          onHoverCard={onHoverCard2}
          setOnHoverCard={setOnHoverCard2}
          icon={PlanIcon}
          color="#266b2e"
          title="Requisitos e Gestão"
          description="Tenha todas as funcionalidades disponíveis com a junção dos
          nossos dois módulos!"
          features={[
            'Todas as funcionalidades do módulo Requisitos Ambientais.',
            'Todas as funcionalidades do módulo Gestão Operacional.',
          ]}
          width="136px"
          height="157px"
        />
      </Stack>
      <Stack
        gap={4}
        direction={['column', 'column', 'row']}
        sx={{ width: '100%', display: { xs: 'flex', md: 'none' } }}
      >
        <Carousel>
          <PricingCard
            onHoverCard={onHoverCard1}
            setOnHoverCard={setOnHoverCard1}
            icon={Plan1Icon}
            color="#5fb2d6"
            title=" Requisitos Ambientais"
            description="Este módulo avisa rapidamente sobre contaminação no sistema de distribuição, sua localização com alarmes personalizados por meio de um SMS ou e-mail."
            features={[
              'Gerencie os resultados de análises das estações de tratamento e redes de distribuição. Planeje o trabalho de campo incluindo aplicativos móveis e otimização de rotas.',
              'Imprima automaticamente seu Relatório do Sisagua. Visualize suas zonas prioritárias pelos registros de não conformidade.',
              'Programe planos de recoletas.',
              'Reduza custos com análises laboratoriais.',
            ]}
            height="157px"
            width="100px"
          />
          <PricingCard
            onHoverCard={onHoverCard3}
            setOnHoverCard={setOnHoverCard3}
            icon={Plan2Icon}
            color="#8cd049"
            title="Gestão Operacional"
            description="Este módulo é usado para controle e monitoramento com integração
          de dados para uma maior eficiência nas ações operacionais."
            features={[
              'Planeje o trabalho de campo incluindo aplicativos móveis.',
              'Obtenha Relatórios Gerenciais para estratégia operacional.',
              'Melhore o desempenho dos processos.',
              'Monitore o consumo e estoque de produtos químicos das unidades.',
              'Reduza custos com análises laboratoriais.',
              'Diminua custos operacionais das plantas.',
              'Ganhe assertividade no direcionamento de investimentos.',
            ]}
            height="157px"
            width="156px"
          />
          <PricingCard
            onHoverCard={onHoverCard2}
            setOnHoverCard={setOnHoverCard2}
            icon={PlanIcon}
            color="#266b2e"
            title="Requisitos e Gestão"
            description="Tenha todas as funcionalidades disponíveis com a junção dos
          nossos dois módulos!"
            features={[
              'Todas as funcionalidades do módulo Requisitos Ambientais.',
              'Todas as funcionalidades do módulo Gestão Operacional.',
            ]}
            width="136px"
            height="157px"
          />
        </Carousel>
      </Stack>
    </Stack>
  );
};

export default Pricing;
