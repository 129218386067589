import { ReactElement } from 'react';
import {
  Stack,
  Typography,
  Card,
  CardContent,
  Button,
  useMediaQuery,
} from '@mui/material';

import { handleAnchorLinkClick } from '../../../utils/functions';
import { East } from '@mui/icons-material';

const PricingCard = (props: any): ReactElement => {
  const {
    onHoverCard,
    setOnHoverCard,
    icon,
    title,
    description,
    features,
    color,
    width,
    height,
  } = props;
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Card
      onMouseEnter={() => setOnHoverCard(true)}
      onMouseLeave={() => setOnHoverCard(false)}
      sx={{
        width: ['100%'],
        padding: '0',
        '&:hover': {
          transform: isMobile ? 'scale(1)' : 'scale(1.10)',
          transition: 'all 0.3s',
        },
      }}
    >
      <CardContent
        sx={{
          alignItems: 'center',
          padding: '0',
          borderColor: 'black',
        }}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          height="100%"
          gap={0.5}
        >
          <Stack
            m={3}
            sx={{
              backgroundImage: `url(${icon})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              width,
              height,
              alignSelf: 'center',
              opacity: onHoverCard || isMobile ? '1' : '0.5',
            }}
          ></Stack>
          <Stack
            sx={{ height: ['250px', '250px', '450px', '250px'] }}
            justifyContent="space-between"
          >
            <Typography
              sx={{
                width: '100%',
                fontSize: '24px',
                fontFamily: 'Sora',
                color,
                textAlign: 'center',
                fontWeight:
                  onHoverCard || isMobile ? 'bold' : 'normal',
              }}
              px={2}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                width: '100%',
                fontSize: '16px',
                fontFamily: 'Sora',
                color: '#26476D',
                fontWeight:
                  onHoverCard || isMobile ? 'normal' : 'lighter',
              }}
              px={2}
            >
              {description}
            </Typography>
            <Stack px={2} width="100%">
              <Button
                sx={{
                  fontSize: ['12px', '16px'],
                  fontFamily: 'Sora',
                  whiteSpace: 'nowrap',
                  padding: '0 0',
                  alignSelf: 'center',
                  textTransform: 'uppercase',
                  color: '#26476D',
                  '&:hover': {
                    backgroundColor: 'transparent',
                  },
                }}
                onClick={() => handleAnchorLinkClick('CONTACT')}
              >
                Fale Conosco{' '}
                <East
                  sx={{
                    fontSize: ['20px', '30px'],
                    ml: ['0.5rem', '0.6rem'],
                  }}
                />
              </Button>
            </Stack>
          </Stack>
          <hr
            color="#26476D"
            style={{
              width: '100%',
              margin: '24px 0',
              borderWidth: '0.1px',
            }}
          />
          <Stack
            sx={{
              width: '100%',
              fontSize: '12px',
              fontFamily: 'Sora',
              color: '#26476D',
            }}
            gap={1}
            px={2}
          >
            {features.map((feature: string) => (
              <Stack direction="row" key={feature}>
                <li style={{ color: '#26476D' }}> {feature}</li>
              </Stack>
            ))}
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default PricingCard;
