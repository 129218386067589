import { CssBaseline } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate } from 'react-router-dom';

import Routes from './routes';
import { Snackbar } from './components';
import { useSnackbar, useFirebase } from './hooks';
import { CookieBar } from './components/CookieBar';
import { Loading } from './components/Loading';

import '@fontsource/sora';
import '@fontsource/work-sans';

const App = (): JSX.Element => {
  const { open, message, severity, closeSnackbar } = useSnackbar();

  const { instance } = useFirebase();

  if (!instance) {
    return <Loading />;
  }

  return (
    <ErrorBoundary
      fallback={<Navigate to="/" replace />}
      onError={(error, info) => console.warn({ error, info })}
    >
      <CssBaseline />
      <Routes />
      <CookieBar />
      <Snackbar
        open={open}
        message={message}
        severity={severity}
        onClose={closeSnackbar}
      />
    </ErrorBoundary>
  );
};

export default App;
