import { getAnalytics, isSupported } from "firebase/analytics";
import { getAuth } from "firebase/auth";

import getFirebase from "../services/firebase";

const app = getFirebase();

const analyticsService = async () =>
  (await isSupported()) ? getAnalytics(app) : null;

const authService = getAuth(app);

export { analyticsService, authService };
