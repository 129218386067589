export const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="171.912"
    height="40"
    viewBox="0 4.5 171.912 40"
  >
    <g data-name="Logo">
      <g data-name="logo_aquios_cor 1 (1)">
        <g data-name="Grupo 1">
          <path
            d="M18.712 11.758h-5.688L0 43.888h7.032l8.554-22.861 8.77 22.861h7.078l-12.722-32.13Z"
            fill="#26476d"
            fillRule="evenodd"
            data-name="Caminho 1"
          />
        </g>
        <g data-name="Grupo 2">
          <path
            d="M74.996 11.758v19.755c0 1.607.043 3.255.911 4.862.783 1.39 2.215 2.433 4.342 2.433 2.172 0 3.604-1 4.298-2.301.911-1.562.911-3.256.911-4.994V11.758h6.47v19.755c0 4.776-.91 7.642-3.386 9.9-1.955 1.868-4.689 3.083-8.25 3.083-2.301 0-5.992-.608-8.379-3.083-2.952-2.823-3.387-5.602-3.387-9.9V11.758h6.47Z"
            fill="#26476d"
            fillRule="evenodd"
            data-name="Caminho 2"
          />
        </g>
        <g data-name="Grupo 3">
          <path
            d="M97.92 11.758h6.47v32.13h-6.47v-32.13Z"
            fill="#26476d"
            fillRule="evenodd"
            data-name="Caminho 3"
          />
        </g>
        <g data-name="Grupo 4">
          <path
            d="M156.585 33.902c.218 4.341 3.126 5.166 4.516 5.166 2.475 0 4.384-1.997 4.384-4.255 0-2.736-2.3-3.43-6.773-5.21-2.433-.867-8.032-2.865-8.032-8.943 0-6.166 5.427-9.507 10.636-9.507 4.255 0 9.9 2.258 10.16 9.378h-6.38c-.261-1.476-.826-3.951-3.995-3.951-2.129 0-3.952 1.476-3.952 3.647-.043 2.388 1.694 2.997 7.121 5.253 4.906 2.127 7.642 4.473 7.642 9.032 0 5.166-3.17 9.986-10.725 9.986-7.295 0-11.072-4.385-11.029-10.594h6.427Z"
            fill="#26476d"
            fillRule="evenodd"
            data-name="Caminho 4"
          />
        </g>
        <g data-name="Grupo 5">
          <path
            d="M49.278 38.284c-.502.078-1.02.12-1.55.12-5.823 0-10.449-4.756-10.449-10.58 0-5.823 4.624-10.58 10.45-10.58 5.823 0 10.492 4.757 10.492 10.58 0 2.002-.577 3.824-1.512 5.425l-4.047-5.246h-7.38l12.226 16.051h7.558l-4.402-5.647c2.357-2.89 3.69-6.58 3.69-10.58 0-9.205-7.38-16.674-16.627-16.674-9.205 0-16.584 7.47-16.584 16.673 0 9.247 7.382 16.674 16.583 16.674a16.98 16.98 0 0 0 5.57-.944l-4.018-5.272Z"
            fill="#26476d"
            fillRule="evenodd"
            data-name="Caminho 5"
          />
        </g>
        <g data-name="Grupo 6">
          <path
            d="m128.52 36.253.266-.237c-.21.157-.33.243-.33.243l.065-.006Z"
            fill="#8cd049"
            fillRule="evenodd"
            data-name="Caminho 6"
          />
        </g>
        <g data-name="Grupo 7">
          <path
            d="M141.202 33.141c5.203-8.047-1.728-18.011.55-19.802-1.423-.836-2.065 1.319-.708 7.901 1.045 5.065-.655 9.878-4.136 12.156-3.307 2.163-7.8 2.781-8.388 2.854l-2.329 2.082s10.55 1.708 15.01-5.19Z"
            fill="#5fb2d6"
            fillRule="evenodd"
            data-name="Caminho 7"
          />
        </g>
        <g data-name="Grupo 8">
          <path
            d="M122.208 30.543c-.28.065-.55.119-.8.155-2.682.575-11.731-.051-11.45-10.604-2.679 12.711 6.812 11.962 12.25 10.45Z"
            fill="#5fb2d6"
            fillRule="evenodd"
            data-name="Caminho 8"
          />
        </g>
        <g data-name="Grupo 9">
          <path
            d="M138.013 8.07c-1.448-1.194-1.89-1.399-3.001-1.881A21.569 21.569 0 0 0 131.92 5.1c-2.797-.737-5.748-.828-8.553-.095-4.314 1.127-9.34 4.462-10.965 9.437-.629 1.924-.7 4.815.056 6.883.309-.937.649-2.1 1.153-2.967 1.403-2.408 3.001-4.125 5.19-5.903 2.191-1.777 4.77-3.225 7.472-4.21-.103.048-.237.097-.349.149-.789.36-1.637.782-2.353 1.239-1.527.976-2.854 2.04-4.175 3.257a.262.262 0 0 1-.026.024l-.032.032c-1.485 1.5-2.79 3.062-3.837 4.889-.197.342-.343.668-.505 1.01a8.68 8.68 0 0 0-.515 1.377c-.11.41-.293.743-.394 1.172a4.657 4.657 0 0 0-.062 1.883c.097.55.308 1.099.706 1.5.632.63 1.496.864 2.349 1.023 4.6.855 7.536.278 10.07-3.195 1.446-1.982 1.043-2.26 1.967-3.98.1-.185.09-.23.164-.402.206-.492.344-.938.53-1.437.073-.2.153-.442.23-.655.272-.757.578-1.543.89-2.271.084-.196.157-.35.243-.509.14-.256.27-.456.412-.698.035-.058.06-.1.1-.163.038-.063.096-.15.141-.212.045-.06.074-.103.119-.165l.153-.205c.043-.058.065-.089.108-.142l.15-.19.106-.134c.043-.051.105-.127.15-.176.046-.05.067-.076.113-.123l.155-.164.11-.114c.043-.045.107-.108.15-.15.044-.044.067-.063.11-.102.043-.039.106-.088.151-.125.043-.037.07-.058.11-.09.043-.035.104-.08.147-.113.043-.032.066-.053.11-.084.04-.03.099-.07.142-.1.04-.031.067-.05.11-.078.04-.028.094-.063.135-.09.041-.029.071-.05.112-.076l.134-.084.114-.071c.041-.026.088-.05.13-.074a2.983 2.983 0 0 1 .239-.125l.12-.053c.041-.018.078-.033.119-.048.04-.015.084-.034.123-.05.038-.014.07-.025.112-.038l.127-.043.103-.033c.039-.013.095-.028.134-.038l.097-.026c.038-.011.103-.026.142-.037l.088-.02c.911-.202 1.95-.187 2.876-.195l-.913-.853Z"
            fill="#8cd049"
            fillRule="evenodd"
            data-name="Caminho 9"
          />
        </g>
        <g data-name="Grupo 10">
          <path
            d="M146.668 30.347c.67-3.428.517-7.077-.774-10.346-.825-2.092-2.074-5.442-4.143-6.662-2.277 1.79 4.654 11.755-.55 19.802-4.459 6.9-15.01 5.19-15.01 5.19l2.33-2.08c-.044.004-.067.008-.067.008s.12-.086.33-.243l.327-.293-.328.293c1.411-1.065 6.834-5.378 5.942-8.21-1.025-3.248-3.837.104-12.216 2.668-.105.026-.202.048-.303.07-5.438 1.512-14.93 2.26-12.25-10.45-.22.521-.41 1.282-.559 1.797-1.657 5.707.506 12.983 5.712 17.714a19.334 19.334 0 0 0 9.684 4.63l.465.069.188.024c.599.075 1.213.127 1.84.15.125.005.25.01.377.01l.345.006c8.822-.026 16.264-5.984 18.66-14.147Z"
            fill="#26476d"
            fillRule="evenodd"
            data-name="Caminho 10"
          />
        </g>
      </g>
    </g>
  </svg>
);
