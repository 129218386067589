import { Cookies } from 'react-cookie-consent';
import { Buffer } from 'buffer';
import { User } from '../context/Auth/AuthContextModels';

const tokenName = 'tokenWC';

const getToken = () => Cookies.get(tokenName);

const getUserFrom = (token: string): User => {
  const data = token.split('.')[1];

  const decodedData = Buffer.from(data, 'base64').toString('utf-8');

  const parsedUser = JSON.parse(decodedData);

  return parsedUser as User;
};

const setToken = (token: string) => Cookies.set(tokenName, token);

const removeToken = () => Cookies.remove(tokenName);

const CookieService = { getToken, getUserFrom, setToken, removeToken };

export default CookieService;
