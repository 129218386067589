import { Stack } from '@mui/material';
import React, { Suspense, lazy } from 'react';
import { ResponsiveAppBar, Header, Loading } from '../../components';

const Mission = lazy(() => import('../../components/Mission'));
const About = lazy(() => import('../../components/About'));
const Modules = lazy(() => import('../../components/Modules'));
const Pricing = lazy(() => import('../../components/Pricing'));
const Contact = lazy(() => import('../../components/Contact'));
const Footer = lazy(() => import('../../components/Footer'));

const HomePage: React.FC = () => {
  return (
    <Stack>
      <ResponsiveAppBar />
      <Header />
      <Mission />
      <Suspense fallback={<Loading />}>
        <About />
        <Modules />
        <Pricing />
        <Contact />
        <Footer />
      </Suspense>
    </Stack>
  );
};

export default HomePage;
