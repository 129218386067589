import { useEffect, useState } from 'react';
import getFirebase from '../services/firebase';
import { getFirestore } from 'firebase/firestore';

export default function useFirebase() {
  const [instance, setInstance] = useState(false);

  const firebaseInstance = getFirebase();

  const db = getFirestore(firebaseInstance);

  useEffect(() => {
    setInstance(!!getFirebase());
  }, []);

  return { instance, db };
}
