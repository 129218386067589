import React, { ReactNode } from 'react';
import { AuthProvider } from './Auth/AuthContext';
import { SnackbarProvider } from './Snackbar';

interface GlobalProviderProps {
  children: ReactNode;
}

const GlobalProvider: React.FC<GlobalProviderProps> = ({
  children,
}) => (
  <AuthProvider>
    <SnackbarProvider>{children}</SnackbarProvider>
  </AuthProvider>
);

export default GlobalProvider;

export * from './Snackbar';
