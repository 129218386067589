import { Stack, Typography, useMediaQuery } from '@mui/material';
import { ReactElement } from 'react';
import AboutLogo from '../../assets/icons/About.svg';

const About = (): ReactElement => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Stack
      id="ABOUT"
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      px={['16px', 'calc(50% - 600px)']}
      pt={['24px', '60px']}
      pb={['24px', '60px']}
      sx={{
        backgroundColor: '#ffffff',
      }}
    >
      <Stack
        width={['100%', '457px']}
        height="611px"
        alignContent="flex-start"
      >
        <Stack>
          <Typography
            sx={{
              color: '#8cd049',
              fontSize: ['14px', '16px', '16px', '26px'],
              fontFamily: 'Sora',
              fontWeight: '900',
            }}
          >
            Conheça-nos um pouco mais
          </Typography>
        </Stack>
        <Typography
          sx={{
            color: '#26476d',
            fontSize: ['20px', '20px', '20px', '42px'],
            fontFamily: 'Sora',
            fontWeight: '700',
            mt: ['16px', '32px'],
          }}
        >
          Otimização da operacionalidade dos processos
        </Typography>
        <Typography
          sx={{
            color: '#26476d',
            fontSize: ['12px', '16px', '16px', '20px'],
            fontFamily: 'Sora',
            mt: '16px',
          }}
        >
          A Aquios centraliza a gestão operacional por meio de uma
          plataforma altamente intuitiva, com tecnologia inovadora e
          escalável. Os diferentes módulos facilitam a tomada de
          decisões e otimizam os processos operacionais, embasam
          análises para reduzir custos, maximizar a eficiência e
          melhorar os serviços.
        </Typography>
        {isMobile && (
          <Stack
            sx={{
              backgroundImage: `url(${AboutLogo})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: '100% 50%',
              width: '100%',
              height: '371px',
            }}
          ></Stack>
        )}
      </Stack>
      {!isMobile && (
        <Stack
          sx={{
            backgroundImage: `url(${AboutLogo})`,
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% 30%',
            width: '645px',
            height: '411px',
          }}
        ></Stack>
      )}
    </Stack>
  );
};

export default About;
