import { useState } from 'react';
import {
  CheckCircle,
  Close,
  Error,
  Warning,
} from '@mui/icons-material';
import {
  Snackbar as MUISnackbar,
  Stack,
  Typography,
} from '@mui/material';

interface SnackbarProps {
  open: boolean;
  message: string;
  onClose: () => void;
  severity: 'success' | 'warning' | 'error';
}

const Snackbar = ({
  open,
  onClose,
  severity,
  message,
}: SnackbarProps) => {
  const [icon] = useState({
    success: <CheckCircle />,
    error: <Error />,
    warning: <Warning />,
  });

  const [backgroundColor] = useState({
    success: '#66bb6a',
    error: '#f44336',
    warning: '#ffa726',
  });

  return (
    <MUISnackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <div>
        <Stack
          direction="row"
          alignItems="center"
          p={2}
          sx={{
            width: '100%',
            backgroundColor: backgroundColor[severity] || '#fff',
            color: 'white',
            borderRadius: '10px',
          }}
        >
          {icon[severity]}
          <Typography mx={1} variant="button">
            {message}
          </Typography>
          <Close
            onClick={onClose}
            sx={{ color: 'white', cursor: 'pointer' }}
          />
        </Stack>
      </div>
    </MUISnackbar>
  );
};

export default Snackbar;
