import { ReactElement } from 'react';
import { Button, Stack, Typography, SxProps } from '@mui/material';

import { handleAnchorLinkClick } from '../../utils/functions';

import { Logo } from '../../assets/icons';

import LinkedinIcon from '../../assets/icons/LinkedinIcon.svg';
import YoutubeIcon from '../../assets/icons/YoutubeIcon.svg';

const Footer = (): ReactElement => {
  const iconsAndMedia = [
    {
      icon: LinkedinIcon,
      link: 'https://www.linkedin.com/company/aquios-brasil/about/',
    },
    {
      icon: YoutubeIcon,
      link: 'https://www.youtube.com/channel/UCIw-6hJNq4qPa2t2ckKLARQ',
      margin: '2px 0px 0px 0px',
    },
  ];

  const LinkStylesPropsSX: SxProps = {
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: '18px',
    fontFamily: 'Work Sans',
    color: '#26476D',
    padding: '0px',
    whiteSpace: 'nowrap',
  };

  const TypographySylesPropSx: SxProps = {
    fontSize: '22px',
    color: '#343c44',
    width: '100%',
    fontFamily: 'Work Sans',
  };

  return (
    <Stack
      px={[2, 2, 'calc(50% - 600px)']}
      py="40px"
      direction={['column', 'column', 'row']}
      gap={[4, 4, 36]}
      justifyContent="center"
    >
      <Stack gap={2} sx={{ justifyContent: 'center', width: '100%' }}>
        <Logo />
        <Typography
          sx={{
            fontSize: '18px',
            color: '#26476D',
            width: ['100%', '70%'],
            fontFamily: 'Work Sans',
          }}
        >
          Solução inteligente para gestão do saneamento.
        </Typography>
        <Stack direction="row" sx={{ width: '200px' }} gap={2}>
          {iconsAndMedia.map((item: any, index: number) => (
            <img
              src={item.icon}
              alt={'icon'}
              key={index}
              width="16px"
              height="16px"
              onClick={() => window.open(item.link, '_blank')}
              style={{
                pointerEvents: 'all',
                cursor: 'pointer',
                margin: item.margin ?? 0,
              }}
              loading="lazy"
            />
          ))}
        </Stack>
      </Stack>
      <Stack direction={['column', 'row']} gap={[4, 16]}>
        <Stack alignItems="flex-start">
          <Typography sx={TypographySylesPropSx} mb={1}>
            Soluções
          </Typography>
          <Stack gap={[2, 0]} alignItems="flex-start">
            <Button
              variant="text"
              sx={LinkStylesPropsSX}
              onClick={() => handleAnchorLinkClick('PRODUCTS')}
            >
              Requisitos Ambientais
            </Button>
            <Button
              variant="text"
              sx={LinkStylesPropsSX}
              onClick={() => handleAnchorLinkClick('PRODUCTS')}
            >
              Gestão Operacional
            </Button>
          </Stack>
        </Stack>
        <Stack alignItems="flex-start">
          <Typography sx={TypographySylesPropSx} mb={1}>
            Institucional
          </Typography>
          <Stack gap={[2, 0]} alignItems="flex-start">
            <Button
              variant="text"
              sx={LinkStylesPropsSX}
              onClick={() => handleAnchorLinkClick('MISSION')}
            >
              Quem Somos
            </Button>
            <Button
              variant="text"
              sx={LinkStylesPropsSX}
              onClick={() => handleAnchorLinkClick('ABOUT')}
            >
              Nossa História
            </Button>
            <Button
              variant="text"
              sx={LinkStylesPropsSX}
              onClick={() => handleAnchorLinkClick('CONTACT')}
            >
              Contato
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
