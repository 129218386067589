import React, {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInWithPopup,
  signOut as fSignOut,
  FacebookAuthProvider,
  updateEmail,
  updatePassword,
  updateProfile as fUpdateProfile,
} from 'firebase/auth';
import { AuthService, CookieService } from '../../services';
import {
  AuthContextProviderProps,
  AuthContextType,
  User,
  UserCredentials,
} from './AuthContextModels';

export const AuthContext = createContext({} as AuthContextType);

const AuthProvider: React.FC<AuthContextProviderProps> = ({
  children,
}): ReactElement => {
  const [user, setUser] = useState({} as User);
  const [loading, setLoading] = useState(true);

  const signOut = useCallback(async () => fSignOut(AuthService), []);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      AuthService,
      async (loggedUser) => {
        if (!loggedUser) {
          setUser({} as User);

          CookieService.removeToken();

          setLoading(false);
          return;
        }

        setUser(loggedUser);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [setUser, setLoading]);

  const signInWithEmailPassword = useCallback(
    async (credentials: UserCredentials) => {
      const { email, password } = credentials;

      return signInWithEmailAndPassword(AuthService, email, password);
    },
    []
  );

  const signUpWithEmailAndPassword = useCallback(
    async (credentials: UserCredentials) => {
      const { email, password } = credentials;
      return createUserWithEmailAndPassword(
        AuthService,
        email,
        password
      );
    },
    []
  );

  const resetPassword = useCallback(
    async (email: string) =>
      sendPasswordResetEmail(AuthService, email),
    []
  );

  const signInWithGoogle = useCallback(async () => {
    const provider = new GoogleAuthProvider();

    return signInWithPopup(AuthService, provider);
  }, []);

  const signInWithFacebook = useCallback(async () => {
    const provider = new FacebookAuthProvider();

    return signInWithPopup(AuthService, provider);
  }, []);

  const changeEmail = useCallback(
    async (email: string) => updateEmail(user, email),
    [user]
  );

  const changePassword = useCallback(
    async (password: string) => updatePassword(user, password),
    [user]
  );

  const updateProfile = useCallback(
    async (newUser: User) => {
      await fUpdateProfile(user, newUser);

      setUser(newUser);

      return Promise.resolve();
    },
    [user, setUser]
  );

  const getAuthContextValues = useMemo(
    () => ({
      user,
      loading,
      signInWithEmailPassword,
      signUpWithEmailAndPassword,
      signInWithGoogle,
      signInWithFacebook,
      signOut,
      resetPassword,
      changeEmail,
      changePassword,
      updateProfile,
    }),
    [
      user,
      loading,
      signInWithEmailPassword,
      signUpWithEmailAndPassword,
      signInWithGoogle,
      signInWithFacebook,
      signOut,
      resetPassword,
      changeEmail,
      changePassword,
      updateProfile,
    ]
  );

  return (
    <AuthContext.Provider value={getAuthContextValues}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider };
