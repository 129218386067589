import React, { useEffect } from 'react';
import { getCookieConsentValue, Cookies } from 'react-cookie-consent';
// import { initGA } from '../../utils/GoogleAnalytics';
import CookieConsentComponent from './CookieConsent';

const CookieBar: React.FC = () => {
  const handleAcceptCookie = () => {
    if (!(window as any).GA_INITIALIZED) {
      // initGA();
      (window as any).GA_INITIALIZED = true;
    }
  };

  const handleDeclineCookie = () => {
    Cookies.remove('_ga');
    Cookies.remove('_gat');
    Cookies.remove('_gid');
  };

  useEffect(() => {
    const isConsent = getCookieConsentValue();

    if (isConsent === 'true') {
      handleAcceptCookie();
    }
  }, []);

  return (
    <CookieConsentComponent
      handleAcceptCookie={handleAcceptCookie}
      handleDeclineCookie={handleDeclineCookie}
    />
  );
};

export default CookieBar;
