import {
  Stack,
  Typography,
  Card,
  CardContent,
  useMediaQuery,
} from '@mui/material';
import { Check } from '@mui/icons-material';

interface ModuleCardProps {
  title: string;
  description: string[];
  icon: string;
  bool?: boolean;
}

const ModuleCard: React.FC<ModuleCardProps> = ({
  title,
  description,
  icon,
  bool,
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Card
      sx={{
        width: ['100%'],
      }}
    >
      <CardContent
        sx={{
          alignItems: 'center',
          padding: '16px',
        }}
      >
        <Stack
          direction="row"
          gap={[4, 6]}
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <img
            src={icon}
            alt="icon-card"
            width={isMobile ? (bool ? '100px' : '70px') : '150px'}
            height="150px"
            style={{ ...(bool && { marginTop: '-10px' }) }}
          />
          <Stack>
            <Typography
              sx={{
                width: 'auto',
                fontSize: ['18px', '26px'],
                fontFamily: 'Sora',
                color: '#5fb2d6',
                fontWeight: '700',
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                width: 'auto',
                fontSize: ['10px', '16px'],
                fontFamily: 'Sora',
                color: '#26476D',
              }}
            >
              {description.map((item) => (
                <span
                  key={item}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <Check
                    sx={{
                      width: ['12px', '16px'],
                      height: ['12px', '16px'],
                      color: '#26476D',
                    }}
                  />
                  {item}
                </span>
              ))}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ModuleCard;
