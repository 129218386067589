import { ReactElement } from 'react';
import { Stack, Button, Typography } from '@mui/material';
import { East } from '@mui/icons-material';

import { handleAnchorLinkClick } from '../../utils/functions';

import Head from '../../assets/icons/Head.svg';

const Header = (): ReactElement => {
  return (
    <Stack
      id="HOME"
      alignItems="center"
      height={['350px', '450px', '550px', '711px']}
      pt="50px"
      px={['16px', 'calc(50% - 600px)']}
      sx={{
        backgroundColor: '#FFFFFF',
      }}
    >
      <Stack direction="row" gap={4}>
        <Stack
          gap={['16px', '32px']}
          sx={{
            width: ['50%', '50%', '60%', '60%'],
            height: ['200px', '336px', '446px', '556px'],
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              pt: ['30%', '0px', '0px', '62px'],
              fontSize: ['20px', '30px', '40px', '50px'],
              fontFamily: 'Sora',
              fontWeight: '700',
              color: '#26476d',
            }}
          >
            Solução inteligente para{' '}
            <span style={{ color: '#8cd049' }}>
              gestão operacional
            </span>{' '}
            do saneamento.
          </Typography>
          <Button
            sx={{
              fontSize: ['12px', '26px'],
              fontFamily: 'Sora',
              whiteSpace: 'nowrap',
              padding: '0 0',
              fontWeight: 800,
              alignSelf: 'flex-start',
              textTransform: 'uppercase',
              color: '#5fb2d6',
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            onClick={() => handleAnchorLinkClick('CONTACT')}
          >
            Saiba mais{' '}
            <East
              sx={{
                fontSize: ['20px, 60px', '60px', '40px'],
                ml: ['0.5rem', '0.6rem'],
              }}
            />
          </Button>
        </Stack>
        <Stack>
          <img src={Head} width="100%" height="100%" alt="head" />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
