export const LogoLight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="171.912"
    height="40"
    viewBox="77 349 797.92 185.658"
  >
    <g data-name="logo_aquios_cor 2 (1)">
      <g data-name="Grupo 13">
        <path
          d="M163.85 382.688h-26.4L77 531.818h32.64l39.7-106.11 40.71 106.11h32.85l-59.05-149.13Z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Caminho 13"
        />
      </g>
      <g data-name="Grupo 14">
        <path
          d="M425.09 382.688v91.69c0 7.46.2 15.11 4.23 22.57 3.63 6.45 10.28 11.29 20.15 11.29 10.08 0 16.73-4.64 19.95-10.68 4.23-7.25 4.23-15.11 4.23-23.18v-91.69h30.03v91.69c0 22.17-4.23 35.47-15.72 45.95-9.07 8.67-21.76 14.31-38.29 14.31-10.68 0-27.81-2.82-38.89-14.31-13.7-13.1-15.72-26-15.72-45.95v-91.69h30.03Z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Caminho 14"
        />
      </g>
      <g data-name="Grupo 15">
        <path
          d="M531.49 382.688h30.03v149.13h-30.03v-149.13Z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Caminho 15"
        />
      </g>
      <g data-name="Grupo 16">
        <path
          d="M803.78 485.468c1.01 20.15 14.51 23.98 20.96 23.98 11.49 0 20.35-9.27 20.35-19.75 0-12.7-10.68-15.92-31.44-24.18-11.29-4.03-37.28-13.3-37.28-41.51 0-28.62 25.19-44.13 49.37-44.13 19.75 0 45.95 10.48 47.16 43.53h-29.62c-1.21-6.85-3.83-18.34-18.54-18.34-9.88 0-18.34 6.85-18.34 16.93-.2 11.08 7.86 13.91 33.05 24.38 22.77 9.87 35.47 20.76 35.47 41.92 0 23.98-14.71 46.35-49.78 46.35-33.86 0-51.39-20.35-51.19-49.17h29.83Z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Caminho 16"
        />
      </g>
      <g data-name="Grupo 17">
        <path
          d="M305.72 505.808c-2.33.36-4.73.56-7.19.56-27.03 0-48.5-22.08-48.5-49.11s21.46-49.11 48.5-49.11c27.03 0 48.7 22.08 48.7 49.11 0 9.29-2.68 17.75-7.02 25.18l-18.78-24.35h-34.26l56.75 74.5H379l-20.43-26.21c10.94-13.41 17.13-30.54 17.13-49.11 0-42.72-34.26-77.39-77.18-77.39-42.72 0-76.97 34.67-76.97 77.39 0 42.92 34.26 77.39 76.97 77.39 9.02 0 17.77-1.57 25.85-4.38l-18.65-24.47Z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Caminho 17"
        />
      </g>
      <g data-name="Grupo 18">
        <path
          d="m673.52 496.378 1.23-1.1c-.97.73-1.53 1.13-1.53 1.13l.3-.03Z"
          fill="#8cd049"
          fillRule="evenodd"
          data-name="Caminho 18"
        />
      </g>
      <g data-name="Grupo 19">
        <path
          d="M732.38 481.938c24.15-37.35-8.02-83.6 2.55-91.91-6.6-3.88-9.58 6.12-3.28 36.67 4.85 23.51-3.04 45.85-19.2 56.42-15.35 10.04-36.2 12.91-38.93 13.25l-10.81 9.66s48.97 7.93 69.67-24.09Z"
          fill="#5fb2d6"
          fillRule="evenodd"
          data-name="Caminho 19"
        />
      </g>
      <g data-name="Grupo 20">
        <path
          d="M644.22 469.878c-1.3.3-2.55.55-3.71.72-12.45 2.67-54.45-.24-53.15-49.22-12.43 59 31.62 55.52 56.86 48.5Z"
          fill="#5fb2d6"
          fillRule="evenodd"
          data-name="Caminho 20"
        />
      </g>
      <g data-name="Grupo 21">
        <path
          d="M717.58 365.568c-6.72-5.54-8.77-6.49-13.93-8.73-4.66-2.02-9.44-3.76-14.35-5.05-12.98-3.42-26.68-3.84-39.7-.44-20.02 5.23-43.35 20.71-50.89 43.8-2.92 8.93-3.25 22.35.26 31.95 1.43-4.35 3.01-9.75 5.35-13.77 6.51-11.18 13.93-19.15 24.09-27.4 10.17-8.25 22.14-14.97 34.68-19.54-.48.22-1.1.45-1.62.69-3.66 1.67-7.6 3.63-10.92 5.75-7.09 4.53-13.25 9.47-19.38 15.12-.04.04-.07.07-.12.11-.05.04-.1.1-.15.15-6.89 6.96-12.95 14.21-17.81 22.69-.91 1.59-1.59 3.1-2.34 4.69-.75 1.59-1.36 3.11-1.9 4.76-.18.55-.34 1.06-.49 1.63-.51 1.9-1.36 3.45-1.83 5.44-.68 2.85-.8 5.85-.29 8.74.45 2.55 1.43 5.1 3.28 6.96 2.93 2.93 6.94 4.01 10.9 4.75 21.35 3.97 34.98 1.29 46.74-14.83 6.71-9.2 4.84-10.49 9.13-18.47.46-.86.42-1.07.76-1.87.96-2.28 1.6-4.35 2.46-6.67.34-.93.71-2.05 1.07-3.04a190.5 190.5 0 0 1 4.13-10.54c.39-.91.73-1.62 1.13-2.36.65-1.19 1.25-2.12 1.91-3.24.16-.27.28-.47.46-.76.18-.29.45-.69.66-.98.21-.28.34-.48.55-.77.21-.28.51-.68.71-.95.2-.27.3-.41.5-.66.2-.26.5-.63.7-.88.2-.25.29-.37.49-.62.2-.24.49-.59.7-.82.21-.23.31-.35.52-.57.21-.22.52-.55.72-.76.2-.21.31-.33.51-.53.2-.21.5-.5.7-.7.2-.2.31-.29.51-.47s.49-.41.7-.58c.2-.17.32-.27.51-.42.2-.16.48-.37.68-.52.2-.15.31-.25.51-.39.19-.14.46-.33.66-.47.19-.14.31-.23.51-.36.19-.13.44-.29.63-.42s.33-.23.52-.35l.62-.39c.19-.12.34-.21.53-.33.19-.12.41-.23.6-.34.19-.11.35-.2.54-.3.19-.1.38-.19.57-.28.19-.08.37-.17.56-.25.19-.08.36-.15.55-.22.19-.07.39-.16.57-.23.18-.07.33-.12.52-.18l.59-.2c.18-.06.3-.09.48-.15s.44-.13.62-.18c.18-.05.26-.07.45-.12.18-.05.48-.12.66-.17.18-.04.22-.05.41-.09 4.23-.94 9.05-.87 13.35-.91l-4.24-3.96Z"
          fill="#8cd049"
          fillRule="evenodd"
          data-name="Caminho 21"
        />
      </g>
      <g data-name="Grupo 22">
        <path
          d="M757.75 468.968c3.11-15.91 2.4-32.85-3.59-48.02-3.83-9.71-9.63-25.26-19.23-30.92-10.57 8.31 21.6 54.56-2.55 91.91-20.7 32.02-69.67 24.09-69.67 24.09l10.81-9.66c-.2.02-.31.04-.31.04s.56-.4 1.53-1.13l1.52-1.36-1.52 1.36c6.55-4.94 31.72-24.96 27.58-38.1-4.76-15.08-17.81.48-56.7 12.38-.49.12-.94.22-1.41.32-25.24 7.02-69.29 10.49-56.86-48.5-1.02 2.42-1.9 5.95-2.59 8.34-7.69 26.49 2.35 60.26 26.51 82.22 12.45 11.04 27.89 18.67 44.95 21.49.72.11 1.44.22 2.16.32.29.04.58.07.87.11 2.78.35 5.63.59 8.54.7.58.02 1.16.04 1.75.04l1.6.03c40.95-.12 75.49-27.77 86.61-65.66Z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Caminho 22"
        />
      </g>
    </g>
  </svg>
);
