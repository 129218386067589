import { Stack } from '@mui/material';
import { ReactElement } from 'react';
import { Logo } from '../../assets/icons';

const Loading = (): ReactElement => (
  <Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{
      height: window.innerHeight,
      animation: 'loadingBlink 1s linear infinite',
    }}
  >
    <Logo />
  </Stack>
);

export default Loading;
