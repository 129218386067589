import { useState, useMemo, useCallback } from 'react';
import {
  Stack,
  Button,
  Typography,
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  useScrollTrigger,
  Slide,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import { handleAnchorLinkClick } from '../../utils/functions';
import { LogoLight } from '../../assets/icons';

const pages = [
  { name: 'Home', anchor: 'HOME' },
  { name: 'Produtos', anchor: 'PRODUCTS' },
  { name: 'Vantagens', anchor: 'ADVANTAGES' },
  { name: 'Contato', anchor: 'CONTACT' },
];

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(
    null
  );

  const handleOpenNavMenu = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleClick = useCallback(
    (anchor: string, isMobile?: boolean) => {
      handleCloseNavMenu();
      handleAnchorLinkClick(anchor, isMobile);
    },
    []
  );

  const appBar = useMemo(
    () => (
      <AppBar position="sticky" variant="elevation">
        <Stack
          py="16px"
          px={['16px', 'calc(50% - 600px)']}
          sx={{
            backgroundColor: '#26476d',
          }}
        >
          <Toolbar
            disableGutters
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <LogoLight />
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                sx={{ color: '#26476D' }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem
                    key={page.name}
                    onClick={() => handleClick(page.anchor, true)}
                  >
                    <Typography textAlign="center">
                      {page.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex', gap: '16px' },
              }}
            >
              {pages.map((page) => (
                <Button
                  variant="text"
                  key={page.name}
                  onClick={() => handleClick(page.anchor)}
                  sx={{
                    boxShadow: 'none',
                    textTransform: 'none',
                    fontSize: '18px',
                    fontFamily: 'Work Sans',
                    color: '#ffffff',
                    backgroundColor: 'transparent',
                    px: '16px',
                    '&:hover': {
                      backgroundColor: '#dddddd',
                      color: '#26476d',
                    },
                  }}
                >
                  {page.name}
                </Button>
              ))}
            </Box>
          </Toolbar>
        </Stack>
      </AppBar>
    ),
    [anchorElNav, handleClick]
  );

  const trigger = useScrollTrigger();

  return <Slide in={!trigger}>{appBar}</Slide>;
};

export default ResponsiveAppBar;
