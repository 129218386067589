import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

import ModuleCard from './components/ModuleCard';
import GestaoIcon from '../../assets/icons/Gestao.svg';
import RequisitosIcon from '../../assets/icons/Requisitos.svg';

const firstModuleCardItems = [
  'Controle de qualidade',
  'Cadastro e validação de dados',
  'Relatório Sisagua',
  'Gestão de custos laboratorias',
];

const secondModuleCardItems = [
  'Planejador de Tarefas',
  'Estoque de Químicos',
  'Relatórios Operacionais',
  'Gestão de custos',
];

const Modules = (): ReactElement => {
  return (
    <>
      <Stack
        pb={2}
        alignItems="center"
        px={['16px', 'calc(50% - 600px)']}
      >
        <Typography
          sx={{
            color: '#26476D',
            fontSize: ['16px', '28px'],
            textAlign: ['center'],
            fontFamily: 'Work Sans',

            width: '100%',
          }}
          mt={[-6, 0]}
          pb={[1, 4]}
        >
          <span style={{ fontWeight: 700 }}>Feito sob medida </span>-
          conheça nossos principais módulos
        </Typography>
      </Stack>
      <Stack
        id="PRODUCTS"
        alignItems="center"
        py={['30px', '60px']}
        px={['16px', 'calc(50% - 600px)']}
        sx={{ width: '100%', backgroundColor: '#26476D' }}
      >
        <Stack
          gap={4}
          direction={['column', 'column', 'column', 'row']}
          sx={{ width: '100%' }}
          pb={3}
        >
          <ModuleCard
            title="Requisitos Ambientais"
            icon={GestaoIcon}
            description={firstModuleCardItems}
          />
          <ModuleCard
            title="Gestão Operacional"
            icon={RequisitosIcon}
            description={secondModuleCardItems}
            bool
          />
        </Stack>
      </Stack>
    </>
  );
};

export default Modules;
