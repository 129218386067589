import { Stack, Typography } from '@mui/material';
import { ReactElement } from 'react';

const Mission = (): ReactElement => {
  return (
    <Stack
      id="MISSION"
      pt={['20px', '60px']}
      pb={['0px', '40px']}
      px={['16px', 'calc(50% - 600px)']}
      sx={{
        backgroundColor: '#26476d',
      }}
    >
      <Stack
        direction={['column', 'row']}
        width="100%"
        pb="30px"
        gap={2}
      >
        <Stack sx={{ gap: [1, 2], flexGrow: 1 }}>
          <Typography
            sx={{
              fontSize: ['16px', '24px'],
              color: '#8cd049',
              fontFamily: 'Sora',
              maxWidth: '500px',
            }}
          >
            Quem somos?{' '}
          </Typography>
          <Typography
            sx={{
              fontSize: ['12px', '20px'],
              color: '#ffffff',
              fontFamily: 'Sora',
              maxWidth: '500px',
            }}
          >
            A Aquios é uma plataforma unificada (Software as a Service
            - SaaS) desenvolvida para otimizar a gestão operacional de
            empresas que responsáveis por sistemas de tratamento de
            água e esgoto.
          </Typography>
        </Stack>
        <Stack sx={{ gap: [1, 2] }}>
          <Typography
            sx={{
              fontSize: ['16px', '24px'],
              color: '#8cd049',
              fontFamily: 'Sora',
              maxWidth: '500px',
            }}
          >
            O que e para quem fazemos?{' '}
          </Typography>
          <Typography
            sx={{
              fontSize: ['12px', '20px'],
              color: '#ffffff',
              fontFamily: 'Sora',
              maxWidth: '500px',
            }}
          >
            Fornecemos aos operadores do setor público e privado do
            saneamento, uma solução inteligente para gerenciar os
            processos que envolvem o tratamento e a distribuição de
            água, coleta e tratamento de esgoto.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Mission;
