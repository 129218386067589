import {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';

type SnackbarSeverity = 'success' | 'warning' | 'error';

interface SnackbarOptions {
  severity: SnackbarSeverity;
  message: string;
}

interface ErrorSnackbarOptions {
  message?: string;
  error?: unknown;
}

export interface SnackbarContextType {
  open: boolean;
  severity: SnackbarSeverity;
  message: string;
  openSnackbar: (options: SnackbarOptions) => void;
  openErrorSnackbar: (options?: ErrorSnackbarOptions) => void;
  closeSnackbar: () => void;
}

interface SnackbarContextProviderProps {
  children: ReactNode;
}

export const SnackbarContext = createContext(
  {} as SnackbarContextType
);

const SnackbarProvider = ({
  children,
}: SnackbarContextProviderProps): ReactElement => {
  const [open, setOpen] = useState(false);

  const [severity, setSeverity] =
    useState<SnackbarOptions['severity']>('success');

  const [message, setMessage] = useState('');

  const openSnackbar = useCallback((options: SnackbarOptions) => {
    setSeverity(options.severity);

    setMessage(options.message);

    setOpen(true);
  }, []);

  const openErrorSnackbar = useCallback(
    (options?: ErrorSnackbarOptions) => {
      setSeverity('error');

      setMessage(
        options?.message ||
          'Oops! Algo deu errado. Por favor, tente novamente!'
      );

      setOpen(true);
    },
    []
  );

  const closeSnackbar = useCallback(() => {
    setOpen(false);
  }, []);

  const getSnackbarContextValues = useMemo(
    () => ({
      open,
      severity,
      message,
      openSnackbar,
      openErrorSnackbar,
      closeSnackbar,
    }),
    [
      open,
      severity,
      message,
      openSnackbar,
      openErrorSnackbar,
      closeSnackbar,
    ]
  );

  return (
    <SnackbarContext.Provider value={getSnackbarContextValues}>
      {children}
    </SnackbarContext.Provider>
  );
};

export { SnackbarProvider };
