import { ReactElement } from 'react';

interface PublicRouteProps {
  children: JSX.Element;
}

const PublicRoute = ({
  children,
}: PublicRouteProps): ReactElement => {
  return children;
};

export default PublicRoute;
