import { initializeApp } from 'firebase/app';

import firebaseConfig from '../config/firebaseConfig';

let instance = null;

export default function getFirebase() {
  if (instance) return instance;

  instance = initializeApp(firebaseConfig);

  return instance;
}
