import { useState, useMemo } from 'react';
import {
  Stack,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';

import { collection, addDoc } from 'firebase/firestore';

import { useFirebase, useSnackbar } from '../../hooks';
import { East } from '@mui/icons-material';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const isDisabled = !name || !email || !message;

  const { openSnackbar } = useSnackbar();

  const isMobile = useMediaQuery('(max-width:600px)');

  const { db } = useFirebase();

  const handleClick = async () => {
    try {
      const docRef = await addDoc(collection(db, 'contacts'), {
        from: email,
        to: process.env
          .REACT_APP_EMAIL_SENDER!.split(',')
          .map((email) => email.trim()),
        message: {
          subject: 'Contato via site',
          html: `<!DOCTYPE html>
          <html lang="en">
            <head>
              <meta charset="UTF-8" />
            </head>
            <body>
              <div id="root">
                <h5>Contato via Aquios.com.br</h5>
                <p>Nome: ${name}</p>
                <p>Email: ${email}</p>
                <p>Mensagem: ${message}</p>
              </div>
            </body>
          </html>`,
        },
      });

      if (docRef) {
        openSnackbar({
          message: 'Mensagem enviada com sucesso!',
          severity: 'success',
        });

        setName('');
        setEmail('');
        setMessage('');
      }
    } catch (e) {
      openSnackbar({
        message: 'Erro ao enviar mensagem, tente novamente.',
        severity: 'error',
      });
    }
  };

  const inputStyles = useMemo(
    () => ({
      width: '100%',
      padding: '12px 20px',
      margin: '8px 0',
      fontFamily: 'Work Sans',
      fontWeight: 'bold',
      fontSize: '16px',
      '::placeholder': {
        color: '#000000   ',
      },
    }),
    []
  );

  const labelStyles = useMemo(
    () => ({
      color: '#ffffff ',
      fontSize: '16px',
      fontFamily: 'Work Sans',
      fontWeight: 'bold',
      width: '100%',
    }),
    []
  );

  return (
    <Stack
      alignItems="center"
      sx={{ backgroundColor: '#26476d' }}
      py={['20px', '70px']}
      id="CONTACT"
    >
      <Stack pb={[1, 0]}>
        <Typography
          sx={{
            color: '#ffffff ',
            fontSize: ['28px', '42px'],
            mt: ['0', '20px'],
            fontFamily: 'Sora',
            padding: '0px',
            transform: isMobile
              ? 'translateY(0)'
              : 'translateY(-48px)',
          }}
        >
          Contato
        </Typography>
      </Stack>
      <Stack direction="column">
        <form
          style={{
            display: 'flex',
            gap: '16px',
            flexDirection: 'column',
          }}
        >
          <div style={{ width: isMobile ? '100%' : '700px' }}>
            <label htmlFor="femail" style={labelStyles}>
              E-mail
            </label>
            <input
              type="text"
              id="femail"
              name="email"
              value={email}
              style={inputStyles}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Digite seu e-mail"
            ></input>
          </div>
          <div>
            <label htmlFor="lname" style={labelStyles}>
              Nome
            </label>
            <input
              type="text"
              id="lname"
              name="name"
              value={name}
              style={inputStyles}
              onChange={(e) => setName(e.target.value)}
              placeholder="Digite o nome"
            ></input>
          </div>
          <div>
            <label htmlFor="lmessage" style={labelStyles}>
              Mensagem
            </label>
            <textarea
              id="lmessage"
              name="message"
              value={message}
              rows={8}
              style={inputStyles}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Digite aqui sua mensagem"
            ></textarea>
          </div>
          <Button
            sx={{
              fontSize: ['12px', '16px'],
              fontFamily: 'Sora',
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              padding: '0 0',
              alignSelf: 'flex-end',
              textTransform: 'uppercase',
              color: '#8cd049',
              '&:hover': {
                backgroundColor: 'transparent',
              },
              ':disabled': {
                color: '#8cd049',
                opacity: 0.5,
              },
            }}
            disabled={isDisabled}
            onClick={handleClick}
          >
            Enviar{' '}
            <East
              sx={{
                fontSize: ['20px', '30px'],
                ml: ['0.5rem', '0.6rem'],
              }}
            />
          </Button>
        </form>
      </Stack>
    </Stack>
  );
};

export default Contact;
