import CookieConsent from 'react-cookie-consent';

interface CookieConsentProps {
  handleAcceptCookie: () => void;
  handleDeclineCookie: () => void;
}

const CookieConsentComponent = ({
  handleAcceptCookie,
  handleDeclineCookie,
}: CookieConsentProps) => (
  <CookieConsent
    enableDeclineButton
    flipButtons
    onAccept={handleAcceptCookie}
    onDecline={handleDeclineCookie}
    declineButtonText="Não aceito"
    buttonText="Aceito"
    style={{
      background: '#26476d',
      boxShadow:
        '0px 0px 2px rgba(0, 0, 0, 0.4), 0px 2px 10px rgba(0, 0, 0, 0.2)',
    }}
    declineButtonStyle={{
      background: '#2563aa',
      color: '#FFF',
      alignItems: 'center',
      boxShadow:
        '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.05)',
      fontSize: '12px',
      padding: '3px 12px 3px 12px',
      borderRadius: '6px',
    }}
    buttonStyle={{
      background: '#FFF',
      color: '#222',
      alignItems: 'center',
      boxShadow:
        '0px 0px 2px rgba(0, 0, 0, 0.1), 0px 2px 10px rgba(0, 0, 0, 0.05)',
      fontSize: '12px',
      padding: '3px 12px 3px 12px',
      borderRadius: '6px',
    }}
  >
    Este website usa cookies para melhorar a experiência do usuário.
  </CookieConsent>
);

export default CookieConsentComponent;
